<template>
	<div :class="`tag-network-preview ${ hidden ? 'hidden' : '' }`">
		<a
				:href="href"
				target="_blank"
				class="tag-network-preview__link"
				@mouseover="!hidden && (hover = true)"
				@mouseleave="!hidden && (hover = false)"
				:style="!hidden && hover ? `border-color: ${color}` : ''"
		>
			<slot name="slot-icon" />
			<h2 class="tag-network-preview__name">{{ name }}</h2>
		</a>
		<div v-if="isOwner" class="tag-network-preview__actions">
			<button class="reorder">
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-icon
								v-bind="attrs"
								v-on="on"
						>
							mdi-reorder-horizontal
						</v-icon>
					</template>
					<span>Reorder with drag-and-drop</span>
				</v-tooltip>
			</button>
			<button @click="visibility">
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-icon
								v-bind="attrs"
								v-on="on"
						>
							{{ hidden ? 'mdi-eye-off' : 'mdi-eye' }}
						</v-icon>
					</template>
					<span>{{ hidden ? 'Show' : 'Hide' }}</span>
				</v-tooltip>
			</button>
			<button @click="removed" class="delete">
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-icon
								v-bind="attrs"
								v-on="on"
						>
							mdi-delete
						</v-icon>
					</template>
					<span>Delete</span>
				</v-tooltip>
			</button>
		</div>
	</div>
</template>

<script>
	export default {
		name: "TagPreviwNetwork",
		props: {
			id: {
				type: String,
				required: true,
			},
			name: {
				type: String,
				required: true,
			},
			href: {
				type: String,
				required: true,
			},
			hidden: {
				type: Boolean,
				default: false,
			},
			isOwner: {
				type: Boolean,
				default: false,
			},
			color: {
				type: String,
			},
		},
		data() {
			return {
				hover: false,
				ttt: false
			};
		},
		methods: {
			removed() {
				this.$emit( "removed" );
			},
			visibility() {
				this.$emit( "visibility" );
			},
		},
	};
</script>

<style scoped lang="scss">
	.tag-network-preview {
		position: relative;
		border: 1px dashed $c-white;

		&:hover {
			.tag-network-preview__actions {
				visibility: visible;
				opacity: 1;
			}
		}

		&.sortable-chosen {
			border-color: $c-gray-secondary;
			.reorder {
				i {
					color: $c-brand-primary !important;
					cursor: grabbing !important;
				}
			}
		}

		.reorder {
			i {
				cursor: grab !important;
			}
		}

		&__link {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			width: 15rem;
			height: 15rem;
			border-radius: 50%;
			box-shadow: $shadow !important;
			background-color: $c-white;
			transition: $transition;
			border: 1px solid $c-white;

			&:hover:not(.hidden) {
				/*transform: scale(1.1);*/
			}
		}

		&.hidden {
			filter: grayscale(100%);
			border: none !important;

			.tag-network-preview__link {
				box-shadow: none !important;
			}
		}

		&__name {
			font-size: 1.2rem;
			color: $c-text-primary;
			margin-top: 1rem;
			text-transform: capitalize;
		}

		&__actions {
			position: absolute;
			width: 100%;
			top: 100%;
			left: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			transition: $transition;

			@include breakpoint(desktopMedium) {
				visibility: hidden;
				opacity: 0;
			}

			button {
				margin-right: 1.5rem;

				&:last-of-type {
					margin-right: 0;
				}

				i {
					font-size: 3.2rem !important;
				}
			}
		}
	}
</style>
