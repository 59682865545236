<template>
	<v-dialog
			class="popup"
			v-model="dialog"
			width="400"
			@click:outside="onDialogClose"
	>
		<template #activator="{ on: dialog }">
			<div class="tag-qr-code-trigger">
				<v-btn fab v-on="{ ...dialog }">
					<v-icon color="#eb008b">
						mdi-qrcode
					</v-icon>
				</v-btn>
			</div>
		</template>

		<v-card>
			<v-card-title class="headline grey lighten-2 mb-4">
				<strong>Scan the QR Code</strong>
			</v-card-title>

			<div class="popup__content">
				<qrcode-vue
						:value="qrValue"
						:size="250"
						level="H"
						id="qr-code"
						foreground="#eb008b"
						class="qr-code-wrapper"
				/>
			</div>

			<v-divider></v-divider>

			<v-card-actions class="popup__actions">
				<v-btn
						type="button"
						@click="onDialogClose"
				>
					Close
				</v-btn>
				<v-btn
						color="success"
						@click="download"
				>
					DOWNLOAD
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
	import { mapActions } from "vuex";
	import { mixinBrandName } from "../../mixins/mixinBrandName";
	import QrcodeVue from 'qrcode.vue';


	export default {
		name: "TagQrCode",
		mixins: [mixinBrandName],
		components: { QrcodeVue },
		props: [
			'id',
			'qrValue',
			'filename',
		],
		data() {
			return {
				dialog: false,
			};
		},
		methods: {
			...mapActions( [
				'requestTagDelete',
				'requestGetTags',
			] ),
			onDialogClose: function () {
				this.dialog = false;
			},
			download() {
				const link = document.createElement( 'a' );
				link.download = `${ this.filename }.png`;

				const canvas = document.querySelectorAll( '#qr-code canvas' )[ 0 ];

				const newCanvas = document.createElement( "canvas" );
				const resizedContext = newCanvas.getContext( "2d" );

				newCanvas.height = 500;
				newCanvas.width = 500;

				resizedContext.drawImage( canvas, 150, 150, 200, 200);

				link.href = newCanvas.toDataURL();
				link.click();
			}
		},
	};
</script>
<style lang="scss" scoped>
	.tag-qr-code-trigger {
		position: fixed;
		z-index: 5;
		bottom: 1rem;
		right: 1rem;

		@include breakpoint(overPhone) {
			bottom: 3rem;
			right: 3rem;
		}

		button {
			box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1) !important;
		}
	}
</style>
<style lang="scss">
	.qr-code-wrapper {
		display: flex;
		justify-content: center;
		margin: 4rem 0;
	}
</style>
