var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:("tag-network-preview " + (_vm.hidden ? 'hidden' : ''))},[_c('a',{staticClass:"tag-network-preview__link",style:(!_vm.hidden && _vm.hover ? ("border-color: " + _vm.color) : ''),attrs:{"href":_vm.href,"target":"_blank"},on:{"mouseover":function($event){!_vm.hidden && (_vm.hover = true)},"mouseleave":function($event){!_vm.hidden && (_vm.hover = false)}}},[_vm._t("slot-icon"),_c('h2',{staticClass:"tag-network-preview__name"},[_vm._v(_vm._s(_vm.name))])],2),(_vm.isOwner)?_c('div',{staticClass:"tag-network-preview__actions"},[_c('button',{staticClass:"reorder"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-reorder-horizontal ")])]}}],null,false,3115836024)},[_c('span',[_vm._v("Reorder with drag-and-drop")])])],1),_c('button',{on:{"click":_vm.visibility}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.hidden ? 'mdi-eye-off' : 'mdi-eye')+" ")])]}}],null,false,669523007)},[_c('span',[_vm._v(_vm._s(_vm.hidden ? 'Show' : 'Hide'))])])],1),_c('button',{staticClass:"delete",on:{"click":_vm.removed}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,false,3148171947)},[_c('span',[_vm._v("Delete")])])],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }