<template>
	<Page class="page-tag-preview">
		<a
				v-if="tag.singleDataGet.data && !tag.singleDataGet.data.isOwner"
				class="shop"
				:href="shopLink"
				target="_blank"
		>
			Tap here to get your {{ BRAND_NAME }}
		</a>

		<Loader v-if="tag.singleDataGet.isLoading" />
		<Error v-if="tag.singleDataGet.error" />

		<TagPreview
				v-if="tag.singleDataGet.data"
				:data="tag.singleDataGet.data"
		/>

	</Page>
</template>

<script>
	import Page from "../../components/layouts/LayoutPage";
	import { mixinPaths } from "../../mixins/mixinPaths";
	import Error from "../../components/common/Error";
	import Loader from "../../components/common/Loader";
	import { mapState } from "vuex";
	import { mixinBrandName } from "../../mixins/mixinBrandName";
	import TagPreview from "../../components/Tag/TagPreview";


	export default {
		name: 'TagEdit',
		components: { TagPreview, Page, Error, Loader },
		data() {
			return {
				id: this.$route.params.id,
				isPreview: false,
				shopLink: process.env.VUE_APP_SHOP_URL,
			};
		},
		mixins: [
			mixinPaths,
			mixinBrandName,
		],
		computed: {
			...mapState( [
				'tag'
			] ),
		},
		methods: {},
		created() {
			this.$store.dispatch( "requestGetSingleTagPublic", this.id );
		},
	};
</script>

<style lang="scss" scoped>
	.shop {
		position: fixed;
		z-index: 5;
		top: 8rem;
		left: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 2rem;
		font-weight: $fw-bold;
		height: 5rem;
		width: 100%;
		background: -webkit-linear-gradient(left, $c-brand-primary 30%, black);
		color: $c-white;

		@include breakpoint(overPhone) {
			font-size: 2.3rem;
			height: 6rem;
		}
	}
</style>
