<template>
	<div class="tag-preview">
		<header v-if="isOwner" class="tag-single-header">
			<div class="go-back">
				<router-link :to="paths().home">Dashboard</router-link>
				<span>/</span>
				<router-link :to="paths().dynamic.tag(this.data.token_id)">Edit</router-link>
				<span>/ <b>Preview</b></span>
			</div>
			<Title class="page-title" title="Preview" />
			<div class="page-actions">
				<div v-if="isSomeNetwork" class="page-actions__action">
					<v-btn
							type="submit"
							large
							outlined
							class="btn-submit"
							:loading="tag.singleDataUpdate.isLoading"
							@click.prevent="onSubmit"
					>
						Save
					</v-btn>
				</div>
			</div>
		</header>

		<div class="tag-preview__body">
			<div class="basic">
				<Avatar v-if="this.data.image_url" class="basic__avatar" :img="this.data.image_url" />
				<div class="basic__header">
					<h2
							class="basic__name"
							v-if="tagType === TAG_TYPE_HUMAN && (this.data.first_name || this.data.last_name)"
					>{{ propertyHandler(this.data.first_name) }} {{ propertyHandler(this.data.last_name) }}</h2>
					<h2
							class="basic__name"
							v-if="tagType === TAG_TYPE_ANIMALS && this.data.animal_name"
					>{{ this.data.animal_name }}</h2>
					<h3
							class="basic__location"
							v-if="this.data.address || this.data.city || this.data.country"
					>{{ propertyHandler(this.data.address, '', ', ') }} {{ propertyHandler(this.data.city, '', ', ') }} {{ propertyHandler(this.data.country) }}</h3>
				</div>
				<div class="basic__links">
					<div class="basic__link" v-if="this.data.owner_name">
						Owner Name: <b>{{ this.data.owner_name }}</b>
					</div>
					<div class="basic__link" v-if="this.data.phone">
						Phone: <a :href="`tel:${this.data.phone}`">{{ this.data.phone }}</a>
					</div>
					<div class="basic__link" v-if="this.data.email">
						Email: <a :href="`mailto:${this.data.email}`">{{ this.data.email }}</a>
					</div>
					<div class="basic__link" v-if="this.data.email2">
						Email: <a :href="`mailto:${this.data.email2}`">{{ this.data.email2 }}</a>
					</div>
					<div class="basic__link" v-if="this.data.website">
						Website: <a :href="this.data.website" target="_blank">{{ this.data.website }}</a>
					</div>
					<div class="basic__link" v-if="this.data.website2">
						Website: <a :href="this.data.website2" target="_blank">{{ this.data.website2 }}</a>
					</div>
				</div>
				<p class="basic__bio" v-if="this.data.description">{{ this.data.description }}</p>

				<a
						v-if="tagType === TAG_TYPE_HUMAN"
						class="button-download-file"
						:href="downloadFileUrl"
						target="_blank"
				>
					Download Contacts File
					<v-icon color="white">
						mdi-account-box-outline
					</v-icon>
				</a>
			</div>

			<div class="networks">

				<draggable
						v-model="networks"
						class="networks__wrapper"
						:sort="!!isOwner"
						handle=".reorder"
				>
					<TagPreviwNetwork
							v-for="({
							verbose_id,
							network,
							show,
							value,
						}) in networks"
							:key="verbose_id"
							:id="verbose_id"
							:name="network"
							:hidden="!show"
							:href="
							!!(networksData[verbose_id] && networksData[verbose_id].prefix)
								? `${networksData[verbose_id].prefix}${value}`
								: value
							"
							:color="(networksData[verbose_id] && networksData[verbose_id].color) || ''"
							@removed="onRemove(verbose_id)"
							@visibility="onVisibility(verbose_id)"
							:isOwner="isOwner"
					>
						<v-icon
								slot="slot-icon"
								x-large
								v-if="networksData[verbose_id] && networksData[verbose_id].customIcon"
						>
							{{ `$${verbose_id}` }}
						</v-icon>
						<v-icon
								slot="slot-icon"
								x-large v-else
								:color="networksData[verbose_id] && networksData[verbose_id].color">mdi-{{verbose_id}}
						</v-icon>
					</TagPreviwNetwork>
				</draggable>
			</div>
		</div>

		<div v-if="isOwner && isSomeNetwork" class="footer">
			<v-btn
					type="submit"
					large
					outlined
					class="btn-submit"
					:loading="tag.singleDataUpdate.isLoading"
					@click.prevent="onSubmit"
			>
				Save
			</v-btn>
		</div>

		<TagQrCode
				v-if="isOwner"
				:qrValue="qrUrl"
				:filename="`${BRAND_NAME}${this.data.first_name ? `-${this.data.first_name}` : ''}${this.data.last_name ? `-${this.data.last_name}` : ''}-${this.data.code}`"
		/>
	</div>
</template>

<script>
	import { mixinTagTypes } from "../../mixins/mixinTagTypes";
	import Avatar from "../common/Avatar";
	import TagPreviwNetwork from "./TagPreviwNetwork";
	import { NETWORKS_DATA } from "../../constants/networks";
	import draggable from 'vuedraggable';
	import { mixinPaths } from "../../mixins/mixinPaths";
	import { mixinBrandName } from "../../mixins/mixinBrandName";
	import Title from "../common/Title";
	import { mapState } from "vuex";
	import TagQrCode from "./TagQrCode";
	import paths from "../../constants/paths";
	import { API_TAG_FILE } from "../../../endpoints";
	import {
		SEO_DESCRIPTION,
		SEO_TITLE
	} from "../../constants/seo";
	import seoImage from "../../assets/images/tapme5-seo-image.png";


	export default {
		name: "TagPreview",
		components: { TagQrCode, TagPreviwNetwork, Avatar, draggable, Title },
		props: {
			data: {
				type: Object,
				required: true,
			}
		},
		mixins: [
			mixinTagTypes,
			mixinPaths,
			mixinBrandName,
		],
		computed: {
			...mapState( [
				'tag'
			] ),
		},
		data() {
			return {
				tagType: this.data.type_name,
				isOwner: this.data.isOwner,
				networksData: NETWORKS_DATA,
				qrUrl: `${ process.env.VUE_APP_APP_URL }${ paths.dynamic.tagPreview( this.data.code ) }`,
				networks: [
					...this.data.networks
						.filter( n => this.data.isOwner
							? !!n.value
							: ( !!n.value && !!n.show ) )
						.sort( ( a, b ) => ( a.order != null
							? +a.order
							: Infinity ) - ( b.order != null
							? +b.order
							: Infinity )
						)
				],
				isSomeNetwork: this.data.networks.some( i => !!i.value ),
				downloadFileUrl: `${ process.env.VUE_APP_API_URL }${ API_TAG_FILE }/${ this.data.code }`,
			};
		},
		methods: {
			onSubmit() {
				this.$store.dispatch( "requestUpdateSingleTag", {
					data: {
						...this.data,
						networks: [
							...this.data.networks.map( i => {
								const editedIndex = this.networks.findIndex( o => o.verbose_id === i.verbose_id );
								const editedObject = this.networks[ editedIndex ];

								if ( editedObject ) {
									return {
										...editedObject,
										order: editedIndex + 1,
									};
								}

								return {
									...i,
									value: '',
								};
							} )
						],
					}
				} );
			},
			onRemove( id ) {
				this.networks = [
					...this.networks
						.filter( n => n.verbose_id !== id )
				];
			},
			onVisibility( id ) {
				this.networks = [
					...this.networks
						.map( n => {
							if ( n.verbose_id === id ) {
								return {
									...n,
									show: !n.show,
								};
							}

							return {
								...n,
							};
						} )
				];
			},
			propertyHandler( value, prefix, suffix ) {
				return value
					? `${ prefix
						? prefix
						: '' }${ value }${ suffix
						? suffix
						: '' }`
					: '';
			}
		},
		metaInfo() {
			const title = () => {
				if ( this.tagType === this.TAG_TYPE_ANIMALS ) {
					return this.data.animal_name
						? this.data.animal_name
						: SEO_TITLE;
				}

				if ( !this.data.first_name && !this.data.last_name ) {
					return SEO_TITLE;
				}

				return `${ this.data.first_name
					? `${ this.data.first_name } `
					: '' }${ this.data.last_name
					? this.data.last_name
					: '' }`;
			};

			const description = this.data.description || SEO_DESCRIPTION;

			return {
				title: title(),
				meta: [
					{
						vmid: 'description',
						name: 'description',
						content: description,
					},

					{
						vmid: 'og:title',
						name: 'og:title',
						content: title(),
					},
					{
						vmid: 'og:description',
						name: 'og:description',
						content: description,
					},
					{
						vmid: 'og:url',
						name: 'og:url',
						content: `${ process.env.VUE_APP_APP_URL }${ this.$route.fullPath }`,
					},
					{
						vmid: 'og:image',
						name: 'og:image',
						content: this.data.image_url || seoImage,
					},
				],
			};
		},
	};
</script>

<style lang="scss" scoped>
	.tag-preview {

		&__body {
			display: flex;
			flex-direction: column;
			margin-bottom: 10rem;
			margin-top: 5rem;

			@include breakpoint(overPhone) {
				margin-top: 12rem;
			}

			@include breakpoint(desktopMedium) {
				flex-direction: row;
			}

			.basic {
				color: $c-text-primary;

				@include breakpoint(toDesktopMedium) {
					margin-bottom: 4rem;
				}

				@include breakpoint(desktopMedium) {
					width: 30%;
					margin-right: 5%;
				}

				&__avatar {
					margin-bottom: 1rem;
				}

				&__header {
					margin-bottom: 2rem;
				}

				&__name {
					font-size: 2.6rem;
				}

				&__location {
					font-size: 2rem;
				}

				&__links {
					margin-bottom: 2rem;
				}

				&__link {
					font-size: 1.8rem;
					margin-bottom: 1rem;

					@include breakpoint(desktopMedium) {
						margin-bottom: .5rem;
					}

					a {
						font-weight: bold;
						background: -webkit-linear-gradient(left, $c-brand-primary, black);
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
					}
				}

				&__bio {
					font-size: 1.8rem;
					white-space: pre-line;
					overflow: hidden;
				}
			}

			.networks {
				flex: 1;

				&__wrapper {
					display: grid;
					grid-gap: 6rem 4rem;
					grid-template-columns: repeat(4, 1fr);
					align-items: center;
					justify-items: center;

					@include breakpoint(phone) {
						grid-template-columns: repeat(2, 1fr);
					}
				}
			}
		}

		.footer {
			margin-bottom: 5rem;
		}
	}

	.button-download-file {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 1rem;
		width: 100%;
		font-size: 1.6rem;
		text-transform: uppercase;
		font-weight: $fw-bold;
		border-radius: 4px;
		background: -webkit-linear-gradient(left, $c-brand-primary 30%, black);
		color: $c-white;
		transition: $transition;
		height: 5rem;
		margin-top: 3rem;
		box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.75) !important;

		&:hover {
			border-color: $c-black;
		}

		.v-icon {
			margin-left: 1rem;
		}
	}
</style>
